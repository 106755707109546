import React from "react"
import Layout from "../components/Layout"
import Hero from "../components/modules/HeroBanner"
import {TiSpannerOutline, TiImageOutline} from "react-icons/ti"
import {DualCardsContent, DualCardItem } from "../components/modules/DualCards"


import SEO from "../components/SEO"

export default () => (
  <Layout>
    <SEO title="Welcome to Barron Carpets &amp; Floors" description="Barron Carpets and flooring provide retail and installation of carpets and flooring products across Edinburgh." />
    <Hero alt="Carpet being fitted" filename="carpet-installation" title="Contact us for free pre-sales advice or quotations" label="Contact us" link="/contact"/>

    <section className="container section-spacer dark md:w-9/12 lg:w-8/12">
        <div className="flex flex-wrap items-center -mx-2">
            <div className="lg:w-1/2 px-2 lg:pr-8 mb-6 lg:mb-0">
                <h3>Barron Carpets &amp; Floors</h3>
                <p className="text-2xl">We are an independent carpet and flooring specialist based in Portobello, Edinburgh.</p>
                <p>Our experienced team provide expert guidance on purchasing and installation across Edinburgh and the Lothians.</p>
            </div>
            <div className="lg:w-1/2 px-2 mb-6">
                <blockquote>
                Would highly recommend Barron Carpets and Floors. These guys worked hard to get this job completed over 3 days. I could not be happier with my new carpets, laminate and vinyl flooring! 
                <cite>Leigh-Anne S, Edinburgh</cite>
                </blockquote>
            </div>
        </div>
    </section>


    <div className="bg-gray-300 text-primary-500 w-full">
      <div className="container">
         <DualCardsContent>
<DualCardItem link="/services" icon={<TiSpannerOutline />} linkText="More Info" content="Find out about Barron Carpets and Floors services today. Contact us a by email or telephone." title="Services" />
<DualCardItem link="/gallery" icon={<TiImageOutline />} linkText="Gallery" content="From homes to commercial settings we have you covered. View our portfolio for examples of our work." title="Our Work" />
          </DualCardsContent>
      </div>
    </div>

  </Layout>
)
